<template lang="pug">
  .car-class-settings
    AppNestedRoutesTabs(
      :routes="routes"
    )
</template>

<script>
  export default {
    components: {
      AppNestedRoutesTabs: () => import("@/components/AppNestedRoutesTabs")
    },

    computed: {
      routes() {
        return [
          {
            name: "TimeDivision",
            title: this.$t("header.title.time_division")
          },
          {
            name: "Options",
            title: this.$t("header.title.options")
          },
          {
            name: "DropOffFees",
            title: this.$t("header.title.drop_off_fees")
          },
          {
            name: "CancellationFees",
            title: this.$t("header.title.cancellation_fees")
          },
          {
            name: "About",
            title: this.$t("header.title.about")
          }
        ]
      }
    }
  }
</script>

<style lang="sass" scoped></style>
